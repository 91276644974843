import { createAction, props } from '@ngrx/store';
import { Scheme } from './scheme.model';

export enum SchemeActionType {
  GET_ALL_SCHEMES = '[Scheme] Get All Schemes',
  GET_ALL_SCHEMES_SUCCESS = '[Scheme] Get All Schemes :: Success',
  GET_SCHEME = '[Scheme] Get Scheme',
  GET_SCHEME_SUCCESS = '[Scheme] Get Scheme :: Success',
  CREATE_SCHEME = '[Scheme] Create Scheme',
  CREATE_SCHEME_SUCCESS = '[Scheme] Create Scheme :: Success',
  UPDATE_SCHEME = '[Scheme] Update Scheme',
  UPDATE_SCHEME_SUCCESS = '[Scheme] Update Scheme :: Success',
  RESET_SCHEME_UPDATED = '[Scheme] Reset Scheme Updated',
  DELETE_SCHEME = '[Scheme] Delete Scheme',
  DELETE_SCHEME_SUCCESS = '[Scheme] Delete Scheme :: Success',
  RESET_SCHEME_DELETED = '[Scheme] Reset Scheme Deleted',
  GET_SCHEME_TYPES = '[Scheme] Get Scheme Types',
  GET_SCHEME_TYPES_SUCCESS = '[Scheme] Get Scheme Types :: Success',
}

export const getAllSchemes = createAction(SchemeActionType.GET_ALL_SCHEMES);
export const getAllSchemesSuccess = createAction(
  SchemeActionType.GET_ALL_SCHEMES_SUCCESS,
  props<{ schemes: Scheme[] }>()
);
export const getScheme = createAction(SchemeActionType.GET_SCHEME, props<{ id: number }>());
export const getSchemeSuccess = createAction(
  SchemeActionType.GET_SCHEME_SUCCESS,
  props<{ scheme: Scheme }>()
);
export const createScheme = createAction(
  SchemeActionType.CREATE_SCHEME,
  props<{ scheme: Scheme }>()
);
export const createSchemeSuccess = createAction(
  SchemeActionType.CREATE_SCHEME_SUCCESS,
  props<{ scheme: Scheme }>()
);
export const updateScheme = createAction(
  SchemeActionType.UPDATE_SCHEME,
  props<{ scheme: Scheme }>()
);
export const updateSchemeSuccess = createAction(
  SchemeActionType.UPDATE_SCHEME_SUCCESS,
  props<{ scheme: Scheme }>()
);
export const resetSchemeUpdated = createAction(SchemeActionType.RESET_SCHEME_UPDATED);
export const deleteScheme = createAction(SchemeActionType.DELETE_SCHEME, props<{ id: number }>());
export const deleteSchemeSuccess = createAction(SchemeActionType.DELETE_SCHEME_SUCCESS);
export const resetSchemeDeleted = createAction(SchemeActionType.RESET_SCHEME_DELETED);
export const getSchemeTypesAction = createAction(SchemeActionType.GET_SCHEME_TYPES);
export const getSchemeTypesSuccessAction = createAction(
  SchemeActionType.GET_SCHEME_TYPES_SUCCESS,
  props<{ schemeTypes: string[] }>()
);
